/**
 * Configuration
 */
// Meta tag
const staticMeta = {

  // Open Graph
  OG_TYPE: 'website',

  // Twitter card
  TWITTER_CARD: 'summary_large_image',
  TWITTER_SITE: '@SET_Thailand',
  TWITTER_CREATOR: '@SET_Thailand'
}

// Lazy content
const defaultObserverOption = {
  rootMargin: '0px',
  threshold: 0
}

// Device screen sizes
const deviceSizes = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
  xxxl: 1600
}
// Image sizes
const imageSizes = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1240,
  xxl: 1920
}

const knowledgeTypes = {
  articleDetail: 'ArticleDetail',
  eBookDetail: 'EBookDetail',
  videoDetail: 'VideoDetail',
  podcastDetail: 'PodcastDetail',
  manageInfographic: 'ManageInfographic',
  eLearning: 'ELearning',
  setBook: 'SETBook'
}

// Specific page path list
const specificPagePath = [
  // 'home2',
  'error/404',
  // 'error/500',
  // 'error/503',
  // 'error/oldversion',
  'redirect',
  'authorization',
  'about/event-calendar/holiday',
  'about/event-calendar/event',
  'about/event-calendar/event/overview',
  'about/event-calendar/event/eventdetails',
  'about/join-us/main',
  'about/mediacenter',
  'about/mediacenter/main',
  'about/mediacenter/media-gallery',
  'about/mediacenter/media-gallery/main',
  'about/mediacenter/media-gallery/article',
  'about/mediacenter/media-gallery/infographic',
  'about/mediacenter/media-gallery/video',
  'about/mediacenter/media-gallery/elearning',
  'about/mediacenter/media-gallery/presentation',
  'about/mediacenter/media-gallery/podcast',
  'about/mediacenter/search-result',
  'about/mediacenter/vdo',
  'about/mediacenter/insights',
  'about/mediacenter/insights/main',
  'about/mediacenter/insights/article',
  'about/mediacenter/insights/infographic',
  'about/mediacenter/insights/video',
  'about/mediacenter/insights/elearning',
  'about/mediacenter/insights/presentation',
  // 'about/setsource/insights/video',
  'about/mediacenter/news-release',
  'about/mediacenter/news-release/main',
  'about/mediacenter/news-release/article',
  'about/mediacenter/news-release/infographic',
  'about/mediacenter/news-release/video',
  'about/mediacenter/news-release/elearning',
  'about/mediacenter/news-release/presentation',
  'about/mediacenter/yaek-yae/main',
  'about/mediacenter/yaek-yae/article',
  'about/mediacenter/yaek-yae/video',
  'about/mediacenter/yaek-yae/elearning',
  'about/mediacenter/yaek-yae/infographic',
  'about/mediacenter/yaek-yae/presentation',
  'about/mediacenter/yaek-yae/podcast',
  // 'about/setsource/new-release/video',
  // 'about/setsource/search-result',
  'preview-form',
  'profile/detail',
  'profile/following-bookmark',
  'code-example',
  'event-tracking-report',
  'search-result',
  'market',
  'market/get-quote',
  'market/get-quote/stock',
  'market/get-quote/warrants',
  'market/get-quote/preferred-shares',
  'market/get-quote/dr',
  'market/get-quote/etf',
  'market/get-quote/dr',
  'market/get-quote/drx',
  'market/get-quote/dw',
  'market/product/stock',
  'market/product/stock/overview',
  // 'market/product/stock/overview2',
  'market/product/stock/search',
  'market/product/stock/top-ranking',
  'market/product/stock/quote',
  'market/product/stock/quote/price',
  'market/product/stock/quote/historical-trading',
  'market/product/stock/quote/financial-statement/company-highlights',
  'market/product/stock/quote/financial-statement/latest/balance',
  'market/product/stock/quote/financial-statement/latest/income',
  'market/product/stock/quote/financial-statement/latest/cashflow',
  'market/product/stock/quote/news',
  'market/product/stock/quote/rights-benefits',
  'market/product/stock/quote/company-profile',
  'market/product/stock/quote/company-profile/information',
  'market/product/stock/quote/company-profile/board-of-directors',
  // 'market/product/stock/quote/company-profile/major-shareholders',
  'market/product/stock/quote/major-shareholders',
  'market/product/stock/quote/company-profile/oppday-company-snapshot',
  'market/product/stock/quote/factsheet',
  'market/product/etf',
  'market/product/etf/overview',
  'market/product/etf/marketdata',
  // 'market/product/etf/market-maker', // pagebuilder
  // 'market/product/etf/marketdata', // pagebuilder
  // 'market/product/etf/introduction', // pagebuilder
  'market/product/etf/quote',
  'market/product/etf/quote/price',
  'market/product/etf/quote/rights-benefits',
  'market/product/etf/quote/company-profile/information',
  // 'market/product/etf/quote/company-profile/major-shareholders',
  'market/product/etf/quote/major-shareholders',
  'market/product/etf/quote/factsheet',
  'market/product/dr',
  'market/product/dr/overview',
  // 'market/product/dr/introduction', // pagebuilder
  'market/product/dr/quote',
  'market/product/dr/quote/price',
  'market/product/dr/quote/rights-benefits',
  'market/product/dr/quote/company-profile',
  'market/product/dr/quote/factsheet',
  'market/product/drx',
  'market/product/drx/overview',
  'market/product/drx/news',
  'market/product/drx/marketdata',
  // 'market/product/drx/introduction', // pagebuilder
  'market/product/drx/quote',
  'market/product/drx/quote/price',
  'market/product/drx/quote/rights-benefits',
  'market/product/drx/quote/company-profile',
  'market/product/dw',
  'market/product/dw/overview',
  'market/product/dw/search',
  'market/product/dw/search/most-active-value',
  'market/product/dw/search/most-active-underlying',
  'market/product/dw/search/newly-listed',
  'market/product/dw/search/nearly-expired',
  // 'market/product/dw/introduction', // pagebuilder
  // 'market/product/dw/dw-foreign', // pagebuilder
  'market/product/dw/quote',
  'market/product/dw/quote/price',
  'market/product/dw/quote/factsheet',
  'market/index',
  'market/index/overview',
  'market/index/gms-exchanges/trading',
  // 'market/index/profile', // pagebuilder
  // 'market/index/industry-sector-profile', // pagebuilder
  'market/statistics',
  'market/statistics/investor-type',
  'market/statistics/five-days',
  'market/statistics/nvdr/main',
  'market/statistics/nvdr/outstanding-share',
  'market/statistics/nvdr/trading-by-method',
  'market/statistics/nvdr/trading-by-sector',
  'market/statistics/nvdr/trading-by-stock',
  // 'market/statistics/short-sell',
  'market/statistics/short-sales',
  'market/statistics/short-sales/total-short-sales',
  'market/statistics/short-sales/outstanding-short-positions',
  'market/statistics/foreign-currency-indicative-stock-quote',
  'market/statistics/market-statistics/capital-raise',
  'market/statistics/market-statistics/ipo',
  'market/statistics/market-statistics/monthly-report',
  'market/statistics/program-trading-value',
  'market/stock-calendar',
  'market/stock-calendar/x-calendar',
  'market/market-statistics',
  'market/news-and-alert',
  'market/news-and-alert/news',
  'market/news-and-alert/market-alerts',
  'market/news-and-alert/newsdetails',
  'market/news-and-alert/market-clarification',
  'market/news-and-alert/sign-posting',
  'market/news-and-alert/auditors-opinion',
  'market/news-and-alert/trading-halt-suspension-pause',
  'market/news-and-alert/dynamic-price-band',
  'market/information/member-list/main',
  'market/information/member-list',
  'market/information/securities-list/concessions-list',
  'listing/ipo/upcoming-ipo/set',
  'listing/ipo/upcoming-ipo/mai',
  'listing/ipo/upcoming-ipo/reit',
  'listing/ipo/upcoming-ipo/infrastructure-fund',
  'listing/ipo/upcoming-ipo/foreign-listing',
  'listing/ipo/ipo-statistics',
  'listing/ipo/first-trading-day',
  'listing/ipo',
  'listing/other-instruments/dw/issuers',
  'listing/other-instruments/dw/outstanding-report',
  'listing/other-instruments/dw/eligible-underlying',
  'listing/other-instruments/dw',
  // 'market/news-and-alert/surveillance-c-sign-temporary-trading/summary',
  'market/news-and-alert/surveillance-c-sign-temporary-trading/market-surveillance-measure-list',
  'market/news-and-alert/surveillance-c-sign-temporary-trading/c-sign',
  'market/news-and-alert/surveillance-c-sign-temporary-trading/temporary-trading',
  'rules-regulations',
  'rules-regulations/regulations',
  'rules-regulations/circulars',
  'rules-regulations/forms',
  'rules-regulations/update',
  'rules-regulations/update/regulations',
  'rules-regulations/update/circulars',
  'rules-regulations/update/forms',
  'rules-regulations/search-result',
  'rules-regulations/details',
  // 'rules-regulations/halt',
  // 'rules-regulations/suspension',
  // 'rules-regulations/notice-pending',
  'rules-regulations/regulatory-statistics',
  'rules-regulations/exchange-enquiries',
  // 'rules-regulations/caution',
  'rules-regulations/rules-canceled',
  'tch/overview',
  'tch/rules-regulations',
  'tch/rules-regulations/regulations',
  'tch/rules-regulations/circulars',
  'tch/rules-regulations/forms',
  'tsd/overview',
  'tsd/rules-regulations',
  'tsd/rules-regulations/regulations',
  'tsd/rules-regulations/circulars',
  'tsd/rules-regulations/forms',
  'tsd/services/investors/calculators/stock-dividend',
  'tsd/services/investors/calculators/right-offering',
  'tsd/services/investors/transaction/certificate/name-changes-record',
  'tsd/services/investors/transaction/certificate/certificate-loss-list',
  'tsd/information/company-list',
  'tsd/information/e-proxy-voting',
  'tsd/information/isin-cfi-fisn',
  'tsd/information/debenture',
  'education-research/education/happymoney/glossary',
  'education-research/education/happymoney/knowledge',
  'education-research/education/happymoney/knowledge/main',
  'education-research/education/happymoney/knowledge/article',
  'education-research/education/happymoney/knowledge/video',
  'education-research/education/happymoney/knowledge/elearning',
  'education-research/education/happymoney/knowledge/infographic',
  'education-research/education/happymoney/knowledge/ebook',
  'education-research/education/happymoney/knowledge/podcast',
  'education-research/education/happymoney/search-result',
  'education-research/education/happymoney/knowledge/videoplaylist',
  'education-research/education/happymoney/knowledge/podcastplaylist',
  // research
  'education-research/research',
  'education-research/research/overview',
  // 'education-research/research/research-grant',
  'education-research/research/research-grant/all-research-grant',
  'education-research/research/market-report',
  'education-research/research/market-report/main',
  'education-research/research/market-report/set-monthly-market-report',
  'education-research/research/market-report/market-highlights',
  'education-research/research/market-report/ceo-survey',
  'education-research/research/market-report/investor-sentiment-index-cmri',
  'education-research/research/market-report/international-roundups',
  'education-research/research/article-paper',
  'education-research/research/article-paper/main',
  'education-research/research/article-paper/set-note',
  'education-research/research/article-paper/set-cmri-research-paper',
  'education-research/research/article-paper/set-cmri-working-paper',
  'education-research/research/database/main',
  'education-research/research/database/examples',
  'education-research/research/search-result',
  'education-research/research/database/market-microstructure/examples',
  'education-research/research/database/setsmart/examples',
  'education-research/research/database/cg-database/examples',
  'education-research/research/database/factor-library/examples',
  'education-research/research/database/economic-exposure-universe/examples',
  'education-research/research/database/dividend-universe/examples',
  // SetBook
  'education-research/education/setbook/text-book/main',
  'education-research/education/setbook/text-book/setbook',
  'education-research/education/setbook/pocket-book/main',
  'education-research/education/setbook/pocket-book/setbook',
  // Member
  'member',
  'member/favorites',
  'member/my-profile',
  'member/my-profile/main',
  'member/my-profile/set-member-privilege',
  'member/my-profile/training',
  'member/my-profile/examination',
  'member/my-profile/events',
  'member/my-profile/check-registration-status',
  'member/my-profile/past-training-examination'
]

const specificPageTHOnly = [
  'education-research/education/happymoney/knowledge/main',
  'education-research/education/happymoney/knowledge/article',
  'education-research/education/happymoney/knowledge/video',
  'education-research/education/happymoney/knowledge/elearning',
  'education-research/education/happymoney/knowledge/infographic',
  'education-research/education/happymoney/knowledge/ebook',
  'education-research/education/happymoney/search-result',
  'education-research/research/market-report/international-roundups',
  'education-research/research/database/main',
  'education-research/research/database/market-microstructure/examples',
  'education-research/research/database/setsmart/examples',
  'education-research/research/database/cg-database/examples',
  'education-research/research/database/economic-exposure-universe/examples',
  'education-research/research/database/dividend-universe/examples',
  'education-research/research/research-grant/all-research-grant',
  'about/mediacenter/main',
  'about/mediacenter/media-gallery',
  'about/mediacenter/media-gallery/main',
  'about/mediacenter/media-gallery/article',
  'about/mediacenter/media-gallery/infographic',
  'about/mediacenter/media-gallery/video',
  'about/mediacenter/media-gallery/elearning',
  'about/mediacenter/media-gallery/presentation',
  'about/mediacenter/media-gallery/podcast',
  'about/mediacenter/search-result',
  'about/mediacenter/vdo',
  'about/mediacenter/insights/main',
  'about/mediacenter/insights/article',
  'about/mediacenter/insights/video',
  'about/mediacenter/insights/elearning',
  'about/mediacenter/insights/infographic',
  'about/mediacenter/insights/presentation',
  'about/mediacenter/news-release/main',
  'about/mediacenter/news-release/article',
  'about/mediacenter/news-release/video',
  'about/mediacenter/news-release/elearning',
  'about/mediacenter/news-release/infographic',
  'about/mediacenter/news-release/presentation',
  // 'about/setsource/search-result',
  'about/mediacenter/yaek-yae/main',
  'about/mediacenter/yaek-yae/article',
  'about/mediacenter/yaek-yae/video',
  'about/mediacenter/yaek-yae/elearning',
  'about/mediacenter/yaek-yae/infographic',
  'about/mediacenter/yaek-yae/presentation',
  'about/mediacenter/yaek-yae/podcast',
  'education-research/education/happymoney/knowledge/videoplaylist',
  'education-research/education/happymoney/knowledge/podcast',
  'education-research/education/happymoney/knowledge/podcastplaylist',
  'education-research/education/setbook/pocket-book/main',
  'education-research/education/setbook/pocket-book/setbook',
  'education-research/education/setbook/text-book/main',
  'education-research/education/setbook/text-book/setbook',
  'education-research/education/happymoney/glossary'
]

// const specificRoute = [
//   {
//     name: 'market-product-stock-slug',
//     path: '/market/product/stock/:slug',
//     component: 'pages/market/product/stock/_slug/_.vue'
//   },
//   {
//     name: 'market-product-stock-slug-all',
//     path: '/market/product/stock/:slug/:all',
//     component: 'pages/market/product/stock/_slug/_.vue'
//   }
// ]
const quotePathBySecurType = {
  quoteS: [
    'price',
    'financial-statement/company-highlights',
    'financial-statement/latest/balance',
    'financial-statement/latest/income',
    'financial-statement/latest/cashflow',
    'news',
    'rights-benefits',
    'company-profile/information',
    'company-profile/board-of-directors',
    // 'company-profile/major-shareholders',
    'major-shareholders',
    'company-profile/oppday-company-snapshot',
    'historical-trading'
  ],
  quoteP: [
    'price',
    'rights-benefits',
    'company-profile/information',
    // 'company-profile/major-shareholders',
    'major-shareholders',
    'historical-trading'
  ],
  quoteW: [
    'price',
    'news',
    'rights-benefits',
    'historical-trading'
  ],
  quoteU: [
    'price',
    'news',
    'company-profile/information',
    'historical-trading'
  ],
  ETF: [
    'price',
    'rights-benefits',
    'company-profile/information',
    // 'company-profile/major-shareholders'
    'major-shareholders'
  ],
  DR: [
    'price',
    'rights-benefits'
  ],
  DRX: [
    'price',
    'rights-benefits'
  ],
  quoteF: [
    'price',
    'historical-trading'
  ],
  quoteQ: [
    'price',
    'historical-trading'
  ],
  DW: []
}

export {
  staticMeta,
  defaultObserverOption,
  specificPagePath,
  specificPageTHOnly,
  imageSizes,
  deviceSizes,
  knowledgeTypes,
  quotePathBySecurType
  // specificRoute
}
